import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { RegionModel } from '@app/domain/RegionModel';
import { RegionListModel } from '@app/domain/RegionListModel';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';

export interface RegionState {
  loading: boolean;
  region: RegionModel | null;
  regionDetails: RegionModel | null;
  regions: RegionListModel[] | [];
}

const initialState: RegionState = {
  loading: false,
  region: null,
  regionDetails: null,
  regions: [],
};

export const regionList = createAsyncThunk(
  'region/region-list',
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get<RegionListModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Region/GetAllRegionList`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        },
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);

export const regionDetailsById = createAsyncThunk(
  'region/view-region',
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get<RegionModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Region/GetRegionListById`,
        { params: { Id: id },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
       }
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);


export const fetchRegionDetails = createAsyncThunk(
  'region/fetchDetails',
  async (id, thunkApi) => {
    console.log('Fetching region details for ID:', id);
    try {
      const response = await axiosInstance.get<RegionModel>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Region/GetRegion`, {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      });
      
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);
export const updateRegion = createAsyncThunk(
  'deliveryZone/update-deliveryZone',
  async (data: RegionListModel, thunkApi) => {
    try {

      const response = await axiosInstance.put<RegionListModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Region/UpdateRegion`,
        data, 
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );

      console.log("Update response:", response.data); 
      return response.data;
    } catch (error) {
      console.error("Update failed:", error); 
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);
export const addRegion = createAsyncThunk(
  'region/add-region',
  async (values, thunkApi) => {
    try {
      const response = await axiosInstance.post<RegionListModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Region/SaveRegion`,
        { ...values },
        { headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
       },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);
export const deleteRegion = createAsyncThunk(
  'region/delete-region',
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.delete<RegionListModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Region/DeleteRegion`,
        {
          params: { Id: id },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);
const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(regionList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(regionList.fulfilled, (state, action: PayloadAction<RegionListModel[]>) => {
      state.loading = false;
      state.regions = action.payload;
    });
    builder.addCase(regionList.rejected, (state) => {
      state.loading = false;
      state.regions = [];
    });
    builder.addCase(regionDetailsById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(regionDetailsById.fulfilled, (state, action: PayloadAction<RegionModel>) => {
      state.loading = false;
      state.regionDetails = action.payload;
    });
    builder.addCase(regionDetailsById.rejected, (state) => {
      state.loading = false;
      state.regionDetails = null;
    });
    builder.addCase(fetchRegionDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRegionDetails.fulfilled, (state, action: PayloadAction<RegionModel>) => {
      state.loading = false;
      state.region = action.payload;
    });
    builder.addCase(fetchRegionDetails.rejected, (state) => {
      state.loading = false;
      state.region = null;
    });
  },
});

export const { reducer: regionReducer } = regionSlice;
export const thunks = {
  fetchRegionDetails,
  regionDetailsById,
  regionList,
  updateRegion,
  deleteRegion
};

export default regionReducer;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductModel,ProductDetailModel, Product, BaseResponse } from '@app/domain/ProductModel';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { PromotionModel } from '@app/domain/PromotionModel';

export interface PromotionState {
  loading: boolean;
  Promotions: PromotionModel[] | [];
  Promotion: PromotionModel | null;

}

const initialState: PromotionState = {
  loading: false,
  Promotions: [],
  Promotion: null,
};

export const addPromotion = createAsyncThunk(
  "/promotion/add-promotion",
  async (values, thunkApi) => {
    try {
      const response = await axiosInstance.post<PromotionModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Promotion/SavePromotion`,
        {...values},
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const PromotionList = createAsyncThunk(
  "/promotion/promotion-list",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get<PromotionModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Promotion/GetAllPromotionList`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const UpdatePromotion = createAsyncThunk(
  "promotion/update-promotion",
  async (values, thunkApi) => {
    try {
      const response = await axiosInstance.put<PromotionModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Promotion/UpdatePromotion`,
         {...values},
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const DeletePromotion = createAsyncThunk('promotion/delete-promotion', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.delete<PromotionModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Promotion/Deletepromotion`,
      {
        params: {
          Id: id
        },
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
}
);

export const PromotionDetails = createAsyncThunk(
  "promotion/view-promotion",
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get<PromotionModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Promotion/GetPromotionDetailsById`,{
          params: 
          {
            Id: id
          },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);
  
const PromotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(PromotionList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(PromotionList.fulfilled,(state, action: PayloadAction<PromotionModel[]>) => {
          state.loading = false;
          state.Promotions = action.payload;
      });
      builder.addCase(PromotionList.rejected, (state, action) => {
        state.loading = false;
        state.Promotions = [];
      });
      builder.addCase(PromotionDetails.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(PromotionDetails.fulfilled,(state, action: PayloadAction<PromotionModel>) => {
          state.loading = false;
          state.Promotion = action.payload;
      });
      builder.addCase(PromotionDetails.rejected, (state, action) => {
        state.loading = false;
        state.Promotion = null;
      });
  }
})

export const thunks = {
  PromotionList,
  PromotionDetails,
  UpdatePromotion,
  addPromotion
};

export default PromotionSlice.reducer;

import { FooterLinksModel } from './../../domain/FooterLinksModel';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';

export interface FooterLinksState {
  loading: boolean;
  footers: FooterLinksModel[] | [];
  footer: FooterLinksModel | null;
}

const initialState: FooterLinksState = {
  loading: false,
  footers: [],
  footer: null,
};

export const footersList = createAsyncThunk('/uiSetup/all-footers', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<FooterLinksModel[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Footers/GetAllFootersList`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const footersDetails = createAsyncThunk('uiSetup/footers-details', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.get<FooterLinksModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Footers/GetFooterDetailsById/`,
      {
        params: 
          {
            Id: id
          },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const addFooterLinks = createAsyncThunk('/uiSetup/add-footers', async (values, thunkApi) => {
  try {
    const response = await axiosInstance.post<FooterLinksModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Footers/SaveFootersList`,
      values,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const updateFooterLinks = createAsyncThunk(
  "uiSetup/Update-footers",
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axiosInstance.put<FooterLinksModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Footers/UpdateFooters`, 
        {...values},
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const deleteFooterLinks = createAsyncThunk('uiSetup/delete-footers', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.delete<FooterLinksModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Footers/DeleteFooters`,
      {
        params: {
          Id: id
        },
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});


const footerLinksSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(footersList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(footersList.fulfilled, (state, action: PayloadAction<FooterLinksModel[]>) => {
      state.loading = false;
      state.footers = action.payload;
    });
    builder.addCase(footersList.rejected, (state, action) => {
      state.loading = false;
      state.footers = [];
    });
    builder.addCase(footersDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(footersDetails.fulfilled, (state, action: PayloadAction<FooterLinksModel>) => {
      state.loading = false;
      state.footer = action.payload;
    });
    builder.addCase(footersDetails.rejected, (state, action) => {
      state.loading = false;
      state.footer =null;
    });
  },
});

export const thunks = {
    footersList,
};

export default footerLinksSlice.reducer;

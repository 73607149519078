import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { BaseResponse } from '@app/domain/ProductModel';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { VendorOrder } from '@app/domain/VendorModel';

export interface VendorOrderState {
  loading: boolean;
  vendorOrders: VendorOrder[] | [];
  vendorOrder: VendorOrder | null;
}

const initialState: VendorOrderState = {
  loading: false,
  vendorOrders: [],
  vendorOrder: null,
};

export const vendorOrderList = createAsyncThunk('order/all-vendor-order', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<VendorOrder[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/ProductOrder/GetAllVendorOrderList`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const vendorOrderStatusUpdate = createAsyncThunk('products/update-product-status', async (values, thunkApi) => {
  try {
    const response = await axiosInstance.put<BaseResponse>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Product/UpdateProductStatus`,
      null,
      {
        params: {
          productId: values.productId,
          status: values.status,
        },
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const vendorOrderSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(vendorOrderList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vendorOrderList.fulfilled, (state, action: PayloadAction<VendorOrder[]>) => {
      state.loading = false;
      state.vendorOrders = action.payload;
    });
    builder.addCase(vendorOrderList.rejected, (state, action) => {
      state.loading = false;
      state.vendorOrders = [];
    });
  },
});

export const thunks = {
  vendorOrderList,
  vendorOrderStatusUpdate
};

export default vendorOrderSlice.reducer;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { ConfigurationModel ,ConfigurationImageModel} from '@app/domain/ConfigurationModel';

export interface ConfigurationState {
  loading: boolean;
  themes: ConfigurationModel[] | [];
  configurationImage: ConfigurationImageModel | null;
  APIkey: string | null;
}

const initialState: ConfigurationState = {
  loading: false,
  themes: [],
  configurationImage: {promotionalImageSize: 300, inventoryImageSize: 100},
  APIkey: null,
};

export const configurationImageList = createAsyncThunk('/Configuration/ConfigurationImage-list', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<ConfigurationImageModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Configuration/GetConfigurationListImage`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        }
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const themeList = createAsyncThunk('/theme/theme-list', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<ConfigurationModel[]>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Get/AppTheme`, {
      headers: {
        "Accept":"application/json",
        "Authorization": 'Bearer ' + readToken(),
      },
    });
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const manageTheme = createAsyncThunk('theme/manage-theme', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.put<ConfigurationModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Change/AppTheme/id/${id}`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const apiKeyGenerate = createAsyncThunk('theme/generate-api-key', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.post<ConfigurationModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/GenerateAPIKey`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const getAPIKey = createAsyncThunk('theme/api-key', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<ConfigurationModel>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Get/ApiKey`, {
      headers: {
        "Accept":"application/json",
        "Authorization": 'Bearer ' + readToken(),
      },
    });
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const ConfigurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(themeList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(themeList.fulfilled, (state, action: PayloadAction<ConfigurationModel[]>) => {
      state.loading = false;
      state.themes = action.payload;
    });
    builder.addCase(themeList.rejected, (state, action) => {
      state.loading = false;
      state.themes = [];
    });

    builder.addCase(configurationImageList.fulfilled, (state, action: PayloadAction<ConfigurationImageModel>) => {
      state.loading = false;
      state.configurationImage = {
        inventoryImageSize: action.payload?.inventoryImageSize > 0 ? action.payload?.inventoryImageSize : 100,
        promotionalImageSize: action.payload?.promotionalImageSize > 0 ? action.payload?.promotionalImageSize : 300 ,
      };
    });
    builder.addCase(getAPIKey.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAPIKey.fulfilled, (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.APIkey = action.payload;
    });
    builder.addCase(getAPIKey.rejected, (state, action) => {
      state.loading = false;
      state.APIkey = null;
    });
  },
});

export const thunks = {
  themeList,
  getAPIKey,
  configurationImageList
};

export default ConfigurationSlice.reducer;

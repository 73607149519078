import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { TemplateConfigurationModel } from '@app/domain/TemplateConfigurationModel';

export interface TemplateConfigurationState {
  loading: boolean;
  templateConfigurations: TemplateConfigurationModel[] | [];
  templateConfiguration: TemplateConfigurationModel | null;

}

const initialState: TemplateConfigurationState = {
  loading: false,
  templateConfigurations: [],
  templateConfiguration: null,
};

export const addTemplateConfiguration = createAsyncThunk(
  "/templateConfiguration/add-templateConfiguration",
  async (values, thunkApi) => {
    try {
      const response = await axiosInstance.post<TemplateConfigurationModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/TemplateConfiguration/SaveTemplateConfiguration`,
        {...values},
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const addTemplateConfigurationList = createAsyncThunk(
  "/templateConfiguration/add-templateConfigurationList",
  async (values, thunkApi) => {
    try {
      const response = await axiosInstance.post<TemplateConfigurationModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/TemplateConfiguration/SaveTemplateConfigurationList`,
        values,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const TemplateConfigurationList = createAsyncThunk(
  "/templateConfiguration/templateConfiguration-list",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get<TemplateConfigurationModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/TemplateConfiguration/GetAllTemplateConfigurationList`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const UpdateTemplateConfiguration = createAsyncThunk(
  "templateConfiguration/update-templateConfiguration",
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axiosInstance.put<TemplateConfigurationModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/TemplateConfiguration/UpdateTemplateConfiguration`,
         {...values},
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const TemplateConfigurationDetails = createAsyncThunk(
  "templateConfiguration/view-templateConfiguration",
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get<TemplateConfigurationModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/TemplateConfiguration/GetTemplateConfigurationDetailsById`,{
          params: 
          {
            Id: id
          },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);
  
const templateConfigurationSlice = createSlice({
  name: 'templateConfiguration',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(TemplateConfigurationList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(TemplateConfigurationList.fulfilled,(state, action: PayloadAction<TemplateConfigurationModel[]>) => {
          state.loading = false;
          state.templateConfigurations = action.payload;
      });
      builder.addCase(TemplateConfigurationList.rejected, (state, action) => {
        state.loading = false;
        state.templateConfigurations = [];
      });
      builder.addCase(TemplateConfigurationDetails.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(TemplateConfigurationDetails.fulfilled,(state, action: PayloadAction<TemplateConfigurationModel>) => {
          state.loading = false;
          state.templateConfiguration = action.payload;
      });
      builder.addCase(TemplateConfigurationDetails.rejected, (state, action) => {
        state.loading = false;
        state.templateConfiguration = null;
      });
  }
})

export const thunks = {
  TemplateConfigurationList,
  TemplateConfigurationDetails,
  UpdateTemplateConfiguration,
  addTemplateConfiguration,
  addTemplateConfigurationList
};

export default templateConfigurationSlice.reducer;

import userReducer from '@app/store/slices/userSlice';
import footerLinksReducer from '@app/store/slices/footerLinksSlice';
import authReducer from '@app/store/slices/authSlice';
import authenticationReducer from '@app/store/slices/authenticationSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import templateReducer from '@app/store/slices/templateSlice';
import templateConfigurationReducer from '@app/store/slices/templateConfigurationSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import employeeReducer from '@app/store/slices/employeeSlice';
import leadReducer from '@app/store/slices/leadSlice';
import vendorReducer from '@app/store/slices/vendorSlice';
import vendorOrderReducer from '@app/store/slices/vendorOrderSlice';
import productReducer from '@app/store/slices/productSlice';
import brandReducer from '@app/store/slices/brandSlice';
import themeSetupReducer from '@app/store/slices/themeSetupSlice';
import companyReducer from '@app/store/slices/companySlice';
import orderReducer from '@app/store/slices/OrderSlice';
import offersReducer from '@app/store/slices/offerSlice';
import deliveryZoneReducer from '@app/store/slices/deliveryZoneSlice';
import regionReducer from '@app/store/slices/RegionSlice';
import emailConfigReducer from '@app/store/slices/emailConfigurationSlice';
import commonReducer from '@app/store/slices/CommonSlice';
import productorderReducer from '@app/store/slices/productOrderSlice';
import totalorderReducer from '@app/store/slices/productOrderSlice';
import fulfillmentReducer from '@app/store/slices/fulfillmentSlice';
import ConfigurationReducer from '@app/store/slices/ConfigurationSlice';
import parentCategoryReducer from '@app/store/slices/parentCategorySlice';
import promotionReducer from '@app/store/slices/promotionSlice';
import promotionBannerReducer from '@app/store/slices/promotionBannerSlice';
import blogReducer from '@app/store/slices/blogSlice';
import messageProvidersReducer from '@app/store/slices/messageProvidersSlice';
import subCategoryReducer from '@app/store/slices/subCategorySlice';
import CategoryReducer from '@app/store/slices/parentCategorySlice';
import vendorProductReducer from '@app/store/slices/vendorSlice';
import footerSectionReducer from '@app/store/slices/FooterSectionSlice';
import imageUploadReducer from '@app/store/slices/imageSlice';
import dashboardReducer from '@app/store/slices/dashboardSlice';
import PaymentProviderReducer from '@app/store/slices/paymentProviderSlice';
import ShippingProfileReducer from '@app/store/slices/shippingProfileSlice';
import uiSetupReducer from '@app/store/slices/uiSetupSlice';
import inventoryReducer from '@app/store/slices/productSlice';
import deliveryAreaReducer from '@app/store/slices/deliveryZoneSlice';
import CheckoutConfigReducer from '@app/store/slices/checkoutConfigSlice';
import configurationReducer from '@app/store/slices/ConfigurationSlice';
import loyaltyGroupReducer from '@app/store/slices/loyaltyGroupSlice';
import menuReducer from '@app/store/slices/menuSlice';
export default {
  user: userReducer,
  footers: footerLinksReducer,
  authenticationUser: authenticationReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  template: templateReducer,
  templateConfiguration: templateConfigurationReducer,
  pwa: pwaReducer,
  employee: employeeReducer,
  product: productReducer,
  brand: brandReducer,
  themeSetup: themeSetupReducer,
  company: companyReducer,
  dashboard: dashboardReducer,
  order: orderReducer,
  offers: offersReducer,
  deliveryZones: deliveryZoneReducer,
  checkoutConfig: CheckoutConfigReducer,
  regions:regionReducer,
  emailConfig: emailConfigReducer,
  productorder: productorderReducer,
  common: commonReducer,
  fulfillment: fulfillmentReducer,
  parentCategory: parentCategoryReducer,
  paymentProvider: PaymentProviderReducer,
  ShippingProfile: ShippingProfileReducer,
  promotion: promotionReducer,
  promotionBanner: promotionBannerReducer,
  blog: blogReducer,
  messageProviders: messageProvidersReducer,
  Configuration: ConfigurationReducer,
  subCategory: subCategoryReducer,
  Category: CategoryReducer,
  vendorProduct: vendorProductReducer,
  footerSection:footerSectionReducer,
  lead: leadReducer,
  loyaltyGroup: loyaltyGroupReducer,
  vendor: vendorReducer,
  vendorOrder: vendorOrderReducer,
  image: imageUploadReducer,
  uisetup: uiSetupReducer,
  inventory:inventoryReducer,
  deliveryArea: deliveryAreaReducer,
  totalorder: totalorderReducer,
  configuration:configurationReducer,
  menu:menuReducer
};

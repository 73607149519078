import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { MasterDataModel } from '@app/domain/MasterDataModel';
import { MasterType } from '@app/constants/masterType';

export interface MasterState {
  loading: boolean;
  masterData: MasterDataModel[] | [];
  yStatus: string;
  nStatus: string;
}

const initialState: MasterState = {
  loading: false,
  masterData: [],
  yStatus: 'T',
  nStatus: 'F',
};

export const MasterDataList = createAsyncThunk('/masterData/masterData-list', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<MasterDataModel[]>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/MasterData/GetAllMasterDataList`,  {
      headers: {
        "Accept":"application/json",
        "Authorization": 'Bearer ' + readToken(),
      },
    },
    )
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const deletes3image = createAsyncThunk('Image/s3Delete', async (filePath, thunkApi) => {
  try {
    const response = await axiosInstance.post<[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Image/s3Delete?imageLink=${filePath}`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const CommonSlice = createSlice({
  name: 'MasterData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(MasterDataList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(MasterDataList.fulfilled, (state, action: PayloadAction<MasterDataModel[]>) => {
      state.loading = false;
      state.masterData = action.payload;
      state.yStatus = action.payload.find(item => item.type == MasterType.YN_STATUS && item.name == 'True')?.value ?? 'T';
      state.nStatus = action.payload.find(item => item.type == MasterType.YN_STATUS && item.name == 'False')?.value ?? 'F';
    });
    builder.addCase(MasterDataList.rejected, (state, action) => {
      state.loading = false;
      state.masterData = [];
    });
  },
});

export const thunks = {
  MasterDataList,
};

export default CommonSlice.reducer;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EmployeeModel } from '@app/domain/EmployeeModel';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { Dates } from '@app/constants/Dates';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service'

export interface EmployeeState {
  loading: boolean;
  employees: EmployeeModel[] | [];
  employee: EmployeeModel | null;
}

const initialState: EmployeeState = {
  loading: false,
  employees: [],
  employee: null,
};

export const employeeList = createAsyncThunk(
  "employees/employee-list",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get<EmployeeModel[]>(
        `${process.env.REACT_APP_BASE_URL}/onboarding-app-backend/api/employees`, {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        });
      const employees = response.data?.data;
      return (employees ? employees.map((employee) => { return { ...employee, formattedDOB: Dates.format(employee?.dob, 'DD-MM-YYYY') } }) : employees);
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const employeeDetail = createAsyncThunk(
  "employees/employee-details",
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get<EmployeeModel>(
        `${process.env.REACT_APP_BASE_URL}/onboarding-app-backend/api/employees/${id}`, {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + readToken()
          }
        });
      const employee = response.data?.data;
      return (employee ? { ...employee, formattedDOB: Dates.format(employee?.dob, 'DD-MM-YYYY') } : employee);
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const createEmployee = createAsyncThunk(
  "employees/add-employee",
  async (values, thunkApi) => {
    try {
      console.log(values)
      const response = await axiosInstance.post<EmployeeModel>(
        `${process.env.REACT_APP_BASE_URL}/onboarding-app-backend/api/employees`,
        {...values}, {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);
export const employeeUpdate = createAsyncThunk(
    "employees/employee-update",
    async (values, thunkApi) => {
      try {
        const response = await axiosInstance.put<EmployeeModel>(
          `${process.env.REACT_APP_BASE_URL}/onboarding-app-backend/api/employees/${values.id}`,
          {...values}, {
            headers: {
              "Accept":"application/json",
              "Authorization": "Bearer " + readToken(),
            },
          });
        return response.data;
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );
export const employeeDelete = createAsyncThunk(
  "employees/employee-delete",
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.delete<EmployeeModel>(
        `${process.env.REACT_APP_BASE_URL}/onboarding-app-backend/api/employees/${id}`, {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + readToken()
          }
        });
      return response.data?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // List
      builder.addCase(employeeList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(employeeList.fulfilled,(state, action: PayloadAction<EmployeeModel[]>) => {
          state.loading = false;
          state.employees = action.payload;
      });
      builder.addCase(employeeList.rejected, (state, action) => {
        state.loading = false;
        state.employees = [];
      });
      // Detail
      builder.addCase(employeeDetail.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(employeeDetail.fulfilled,(state, action: PayloadAction<EmployeeModel>) => {
          state.loading = false;
          state.employee = action.payload;
      });
      builder.addCase(employeeDetail.rejected, (state, action) => {
        state.loading = false;
        state.employee = null;
      });
      // Save 
      builder.addCase(createEmployee.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(createEmployee.fulfilled,(state, action: PayloadAction<EmployeeModel>) => {
          state.loading = false;
          state.employees = action.payload.data;
      });
      builder.addCase(createEmployee.rejected, (state, action) => {
        state.loading = false;
        state.employees = [];
      });
      // Update 
      builder.addCase(employeeUpdate.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(employeeUpdate.fulfilled,(state, action: PayloadAction<EmployeeModel>) => {
          state.loading = false;
          state.employee = action.payload;
      });
      builder.addCase(employeeUpdate.rejected, (state, action) => {
        state.loading = false;
        state.employee = null;
      });
      // Delete
      builder.addCase(employeeDelete.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(employeeDelete.fulfilled,(state, action: PayloadAction<EmployeeModel>) => {
          state.loading = false;
          state.employee = action.payload;
      });
      builder.addCase(employeeDelete.rejected, (state, action) => {
        state.loading = false;
        state.employee = null;
      });
  }
})

export const thunks = {
  createEmployee,
  employeeList,
  employeeDetail,
  employeeUpdate,
  employeeDelete
};

export default employeeSlice.reducer;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PaymentProviderModel } from '@app/domain/PaymentProviderModel';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken, readUser } from '@app/services/localStorage.service';
import { VendorModel } from '@app/domain/VendorModel';
import { PaymentSetupModel } from '@app/domain/PaymentSetupModel';
import { UiSetupModel } from '@app/domain/UiSetupModel';

export interface UiSetup {
  loading: boolean;
  uiSetup: UiSetupModel | null;
}

const initialState: UiSetup = {
  loading: false,
  uiSetup: null,
};

export const GetUiSetup = createAsyncThunk('uiSetup/get', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<UiSetupModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/ThemeSetup/GetUiSetup`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const updateUiSetup = createAsyncThunk(
  'uiSetup/update',
  async (values, thunkApi) => {
    try {
      const response = await axiosInstance.put<UiSetupModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ThemeSetup/UpdateUiSetup`,
        { ...values },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + readToken(),
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

const uiSetupSlice = createSlice({
  name: 'UiSetup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Detail
    builder.addCase(GetUiSetup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetUiSetup.fulfilled, (state, action: PayloadAction<UiSetupModel>) => {
      state.loading = false;
      state.uiSetup = action.payload;
    });
    builder.addCase(GetUiSetup.rejected, (state, action) => {
      state.loading = false;
      state.uiSetup = null;
    });
    // Update
    builder.addCase(updateUiSetup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateUiSetup.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateUiSetup.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const thunks = {
  GetUiSetup,
  updateUiSetup,
};

export default uiSetupSlice.reducer;

import { LoyaltyGroupModel } from '@app/domain/LoyaltyGroupModel';
import { readToken } from '@app/services/localStorage.service';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from './../../store/AxiosInstance';

export interface loyaltyGroupState {
  loading: boolean;
  loyaltyGroups: LoyaltyGroupModel[] | [];
  loyaltyGroup: LoyaltyGroupModel | null;
}

const initialState: loyaltyGroupState = {
  loading: false,
  loyaltyGroups: [],
  loyaltyGroup: null,
};

export const loyaltyGroupList = createAsyncThunk('loyaltyGroups/all-loyaltyGroup', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<LoyaltyGroupModel[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/LoyaltyGroup/GetAllLoyaltyGroupList`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + readToken(),
        },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const loyaltyGroupDetail = createAsyncThunk('loyaltyGroups/loyaltyGroup-detail', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.get<LoyaltyGroupModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/LoyaltyGroup/GetLoyaltyGroupById`,
      {
        params: {
          id: id,
        },
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const createloyaltyGroup = createAsyncThunk(
  'loyaltyGroups/loyaltyGroup-request-form',
  async (values, thunkApi) => {
    try {
      const response = await axiosInstance.post<LoyaltyGroupModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/LoyaltyGroup/SaveLoyaltyGroup`,
        { ...values },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + readToken(),
          },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

export const loyaltyGroupUpdate = createAsyncThunk('loyaltyGroups/loyaltyGroup-update', async (values, thunkApi) => {
  try {
    const response = await axiosInstance.put<LoyaltyGroupModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/LoyaltyGroup/UpdateLoyaltyGroup`,
      { ...values },
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const loyaltyGroupDelete = createAsyncThunk('loyaltyGroups/loyaltyGroup-delete', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.put<LoyaltyGroupModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/LoyaltyGroup/DeleteLoyaltyGroup/${id}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + readToken(),
        }
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const loyaltyGroupStatus = createAsyncThunk('loyaltyGroups/loyaltyGroup-status', async (data, thunkApi) => {
  try {
    const response = await axiosInstance.put<LoyaltyGroupModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/LoyaltyGroup/StatusLoyaltyGroup/${data?.id}/${data.status}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + readToken(),
        }
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const loyaltyGroupSlice = createSlice({
  name: 'loyaltyGroup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(loyaltyGroupList.pending, (state, action) => {
      state.loading = true;
      state.loyaltyGroups = [];
    });
    builder.addCase(loyaltyGroupList.fulfilled, (state, action: PayloadAction<LoyaltyGroupModel[]>) => {
      state.loading = false;
      state.loyaltyGroups = action.payload;
    });
    builder.addCase(loyaltyGroupList.rejected, (state, action) => {
      state.loading = false;
      state.loyaltyGroups = [];
    });
    // Detail
    builder.addCase(loyaltyGroupDetail.pending, (state, action) => {
      state.loading = true;
      state.loyaltyGroup = null;
    });
    builder.addCase(loyaltyGroupDetail.fulfilled, (state, action: PayloadAction<LoyaltyGroupModel>) => {
      state.loading = false;
      state.loyaltyGroup = action.payload;
    });
    builder.addCase(loyaltyGroupDetail.rejected, (state, action) => {
      state.loading = false;
      state.loyaltyGroup = null;
    });
    // Save
    builder.addCase(createloyaltyGroup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createloyaltyGroup.fulfilled, (state, action: PayloadAction<LoyaltyGroupModel>) => {
      state.loading = false;
    });
    builder.addCase(createloyaltyGroup.rejected, (state, action) => {
      state.loading = false;
    });
    // Update
    builder.addCase(loyaltyGroupUpdate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loyaltyGroupUpdate.fulfilled, (state, action: PayloadAction<LoyaltyGroupModel>) => {
      state.loading = false;
    });
    builder.addCase(loyaltyGroupUpdate.rejected, (state, action) => {
      state.loading = false;
      state.loyaltyGroup = null;
    });
    // Delete
    builder.addCase(loyaltyGroupDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loyaltyGroupDelete.fulfilled, (state, action: PayloadAction<LoyaltyGroupModel>) => {
      state.loading = false;
    });
    builder.addCase(loyaltyGroupDelete.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const thunks = {
  loyaltyGroupList,
  loyaltyGroupDetail,
  createloyaltyGroup,
  loyaltyGroupUpdate,
  loyaltyGroupDelete,
};

export default loyaltyGroupSlice.reducer;

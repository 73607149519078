import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { TemplateModel } from '@app/domain/TemplateModel';

export interface TemplateState {
  loading: boolean;
  Templates: TemplateModel[] | [];
  Template: TemplateModel | null;

}

const initialState: TemplateState = {
  loading: false,
  Templates: [],
  Template: null,
};

export const addTemplate = createAsyncThunk(
  "/smsTemplate/add-template",
  async (values, thunkApi) => {
    try {
      const response = await axiosInstance.post<TemplateModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Template/SaveTemplate`,
        {...values},
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const TemplateList = createAsyncThunk(
  "/smsTemplate/template-list",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get<TemplateModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Template/GetAllTemplateList`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const UpdateTemplate = createAsyncThunk(
  "smsTemplate/update-template",
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axiosInstance.put<TemplateModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Template/UpdateTemplate`, 
        {...values},
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const TemplateDetails = createAsyncThunk(
  "smsTemplate/view-template",
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get<TemplateModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Template/GetTemplateDetailsById`,{
          params: 
          {
            Id: id
          },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const deleteTemplate = createAsyncThunk('smsTemplate/delete-template', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.delete<TemplateModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Template/DeleteTemplate`,{
        params: {
          Id: id
        },
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});
  
const TemplateSlice = createSlice({
  name: 'smsTemplate',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(TemplateList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(TemplateList.fulfilled,(state, action: PayloadAction<TemplateModel[]>) => {
          state.loading = false;
          state.Templates = action.payload;
      });
      builder.addCase(TemplateList.rejected, (state, action) => {
        state.loading = false;
        state.Templates = [];
      });
      builder.addCase(TemplateDetails.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(TemplateDetails.fulfilled,(state, action: PayloadAction<TemplateModel>) => {
          state.loading = false;
          state.Template = action.payload;
      });
      builder.addCase(TemplateDetails.rejected, (state, action) => {
        state.loading = false;
        state.Template = null;
      });
  }
})

export const thunks = {
  TemplateList,
  TemplateDetails,
  UpdateTemplate,
  addTemplate
};

export default TemplateSlice.reducer;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from './../../store/AxiosInstance';
import { MenuModel } from '@app/domain/MenuModel';
import { MenuListModel } from '@app/domain/MenuListModel';
import { readToken } from '@app/services/localStorage.service';

export interface Menu {
  loading: boolean;
  menu: MenuModel | null;
  menuDetails: MenuModel | null;
  menus: MenuListModel[] | [];
}

const initialState: Menu = {
  loading: false,
  menu: null,
  menuDetails: null,
  menus: [],
};

export const menuList = createAsyncThunk(
  'menu/menu-list',
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get<MenuListModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Menu/GetAllMenuList`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        },
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);

export const menuDetailsById = createAsyncThunk(
  'menu/menu-DetailsById',
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get<MenuModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Menu/GetMenuListById`,
        { params: { Id: id },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
       }
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);


export const fetchMenuDetails = createAsyncThunk(
  'menu/fetchDetails',
  async (id, thunkApi) => {
    console.log('Fetching menu details for ID:', id);
    try {
      const response = await axiosInstance.get<MenuModel>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Menu/GetMenu`, {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      });
      
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);
export const updateMenu = createAsyncThunk(
  'menu/update-menu',
  async (data: MenuListModel, thunkApi) => {
    try {

      const response = await axiosInstance.put<MenuListModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Menu/UpdateMenu`,
        data, 
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      console.log("Update response:", response.data); 
      return response.data;
    } catch (error) {
      console.error("Update failed:", error); 
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);
export const addMenu= createAsyncThunk(
  'menu/add-menu',
  async (values, thunkApi) => {
    try {
      const response = await axiosInstance.post<MenuListModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Menu/SaveMenu`,
        { ...values },
        { headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
       },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);
export const deleteMenu = createAsyncThunk(
  'menu/delete-menu',
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.delete<MenuListModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Menu/DeleteMenu`,
        {
          params: { Id: id },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);
const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(menuList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(menuList.fulfilled, (state, action: PayloadAction<MenuListModel[] >) => {
      state.loading = false;
      state.menus = action.payload;
    });
    builder.addCase(menuList.rejected, (state) => {
      state.loading = false;
      state.menus = [];
    });
    builder.addCase(menuDetailsById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(menuDetailsById.fulfilled, (state, action: PayloadAction<MenuModel>) => {
      state.loading = false;
      state.menuDetails = action.payload;
    });
    builder.addCase(menuDetailsById.rejected, (state) => {
      state.loading = false;
      state.menuDetails = null;
    });
    builder.addCase(fetchMenuDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMenuDetails.fulfilled, (state, action: PayloadAction<MenuModel>) => {
      state.loading = false;
      state.menu = action.payload;
    });
    builder.addCase(fetchMenuDetails.rejected, (state) => {
      state.loading = false;
      state.menu = null;
    });
  },
});

export const { reducer: menuReducer } = menuSlice;
export const thunks = {
  fetchMenuDetails,
  menuDetailsById,
  menuList,
  updateMenu,
  deleteMenu
};

export default menuReducer;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { Fulfillment, BaseResponse } from '@app/domain/FulfillmentModel';

export interface FulfillmentState {
  loading: boolean;
  Fulfillments: Fulfillment[] | [];
  Fulfillment: Fulfillment | null;
  OrderFulfillments: Fulfillment[] | [];
  Fulfillmentparent:Fulfillment[] | [];
  FulfillmentRef: Fulfillment[] | null;
  // invoiceDetails: Fulfillment[] | null;
}

const initialState: FulfillmentState = {
  loading: false,
  Fulfillments: [],
  Fulfillment: null,
  OrderFulfillments: [],
  Fulfillmentparent:[],
  FulfillmentRef:[]
  // invoiceDetails:[]
};

export const fulfillmentList = createAsyncThunk('/fulfillment/fulfillment-list', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<Fulfillment[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Fulfillment/GetAllFulfillmentList`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      }
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

// export const fulfillmentInvoice = createAsyncThunk(
//   "fulfillmentInvoice", 
//   async (OrderNo, thunkApi) => {
//       try {

//           const response = await axios.get(
//               `${process.env.REACT_APP_BASE_URL}/Fulfillment/GetInvoiceDetailsById/${OrderNo}/${localStorage.getItem("companyId")}`,
//               {
//                   // params: {
//                   //   CompanyId: localStorage.getItem("companyId"),
//                   // },
//                   headers: {
//                     Accept: "application/json",
//                   },
//               });
//           return response.data;

//       } catch (error) {
//           return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
//       }
//   }
// );

export const addFulfillment = createAsyncThunk('/fulfillment/fulfillment-save', async (values, thunkApi) => {
  try {
    const response = await axiosInstance.post<Fulfillment[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Fulfillment/SaveFulfillmentDetails`,
      {...values},
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const UpdateFulfillment = createAsyncThunk('fulfillment/Update-fulfillment', async (updatedProducts: Fulfillment[], thunkApi) => {
  try {
      const response = await axiosInstance.put(
          `${process.env.REACT_APP_BASE_URL}/Ecommerce/Fulfillment/UpdateFulfillmentDetails`,
          updatedProducts,
          {
            headers: {
              "Accept":"application/json",
              "Authorization": 'Bearer ' + readToken(),
            },
          },
      );
      return response.data;
  } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const FulfillmentDetails = createAsyncThunk('fulfillment/view-fulfillment', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.get<Fulfillment>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Fulfillment/GetFulfillmentDetailsById`,
      {
        params: {
          Id: id,
        },
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const fulfillmentDetailsByOrderId = createAsyncThunk(
  'fulfillment/view-fulfillment-order-id',
  async (orderId: string, thunkApi) => {
    try {
      const response = await axiosInstance.get<Fulfillment>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Fulfillment/GetFulfillmentDetailsByOrderId`,
        {
          params: {
            OrderId: orderId,
          },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

export const UpdatePOReturnQtyOrRfdAmount = createAsyncThunk('fulfillment/Update-return-refund', async (values, thunkApi) => {
  try {
    const response = await axiosInstance.put<Fulfillment>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Fulfillment/UpdatePORtnQtyOrRfdAmount`,
      values,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const UpdateFulfillmentTracking = createAsyncThunk('fulfillment/Update-tracking', async (values, thunkApi) => {
  try {
    const response = await axiosInstance.put<Fulfillment>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Fulfillment/UpdateFulfillmentTracking`,
      values,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const fulfillmentsList = createAsyncThunk('/fulfillments/fulfillments-list', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<Fulfillment[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Fulfillment/GetAllFulfillmentParentList`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const FulfillmentDetailsByref = createAsyncThunk('fulfillment/view-fulfillmentreference', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.get<Fulfillment[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Fulfillment/GetFulfillmentbyreference`,
      {
        params: {
          FulfillmentId: id,
        },
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const fulfillmentSlice = createSlice({
  name: 'fulfillment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(fulfillmentList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fulfillmentList.fulfilled, (state, action: PayloadAction<Fulfillment[]>) => {
      state.loading = false;
      state.Fulfillments = action.payload;
    });
    builder.addCase(fulfillmentList.rejected, (state, action) => {
      state.loading = false;
      state.Fulfillments = [];
    });
    // details
    builder.addCase(FulfillmentDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FulfillmentDetails.fulfilled, (state, action: PayloadAction<Fulfillment>) => {
      state.loading = false;
      state.Fulfillment = action.payload;
    });
    builder.addCase(FulfillmentDetails.rejected, (state, action) => {
      state.loading = false;
      state.Fulfillment = null;
    });
    builder.addCase(fulfillmentDetailsByOrderId.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fulfillmentDetailsByOrderId.fulfilled, (state, action: PayloadAction<Fulfillment>) => {
      state.loading = false;
      state.OrderFulfillments = action.payload;
    });
    builder.addCase(fulfillmentDetailsByOrderId.rejected, (state, action) => {
      state.loading = false;
      state.OrderFulfillments = [];
    });
    builder.addCase(UpdatePOReturnQtyOrRfdAmount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(UpdatePOReturnQtyOrRfdAmount.fulfilled, (state, action: PayloadAction<BaseResponse>) => {
      state.loading = false;
    });
    builder.addCase(UpdatePOReturnQtyOrRfdAmount.rejected, (state, action) => {
      state.loading = false;
    });

      // list
      builder.addCase(fulfillmentsList.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(fulfillmentsList.fulfilled, (state, action: PayloadAction<Fulfillment[]>) => {
        state.loading = false;
        state.Fulfillmentparent = action.payload;
      });
      builder.addCase(fulfillmentsList.rejected, (state, action) => {
        state.loading = false;
        state.Fulfillmentparent = [];
      });
      
       // detailsbyReference
    builder.addCase(FulfillmentDetailsByref.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FulfillmentDetailsByref.fulfilled, (state, action: PayloadAction<Fulfillment[]>) => {
      state.loading = false;
      state.FulfillmentRef = action.payload;
    });
    builder.addCase(FulfillmentDetailsByref.rejected, (state, action) => {
      state.loading = false;
      state.FulfillmentRef = [];
    });
    // builder.addCase(fulfillmentInvoice.pending, (state, action) => {
    //   state.loading = true;
    // });
    // builder.addCase(fulfillmentInvoice.fulfilled, (state, action: PayloadAction<Fulfillment[]>) => {
    //   state.loading = false;
    //   state.invoiceDetails = action.payload;
    // });
    // builder.addCase(fulfillmentInvoice.rejected, (state, action) => {
    //   state.loading = false;
    //   state.invoiceDetails = [];
    // });
   },
});

export const thunks = {
  fulfillmentList,
  addFulfillment,
  UpdatePOReturnQtyOrRfdAmount,
  FulfillmentDetailsByref
  // fulfillmentInvoice
};

export default fulfillmentSlice.reducer;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AddLead, LeadModel } from '@app/domain/LeadModel';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { VendorModel } from '@app/domain/VendorModel';

export interface LeadState {
  loading: boolean;
  leads: LeadModel[] | [];
  lead: LeadModel | null;
  leadvendor: VendorModel | null;
}

const initialState: LeadState = {
  loading: false,
  leads: [],
  lead: null,
  leadvendor: null,
};

export const leadList = createAsyncThunk(
  "leads/all-lead",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get<LeadModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Leads/GetAllLeadsList`, {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + readToken()
          }
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const leadDetail = createAsyncThunk(
  "leads/lead-detail",
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get<LeadModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Leads/GetLeadsDetailsById`, {
          params: 
          {
            id: id
          },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const leadVendorDetail = createAsyncThunk(
  "leads/lead-vendor-detail",
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get<VendorModel>(
        `${process.env.REACT_APP_BASE_URL}/onboarding-app-backend/api/leads/${id}`, {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + readToken()
          }
        }
      );
      return response.data?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const createLead = createAsyncThunk(
    "leads/lead-request-form",
    async (values, thunkApi) => {
      try {
        const response = await axiosInstance.post<AddLead>(
          `${process.env.REACT_APP_BASE_URL}/Ecommerce/Leads/SaveLeads`,
          {...values} , {
            headers: {
              "Accept":"application/json",
              "Authorization": "Bearer " + readToken()
            }
          }
        );
        console.log(response.data);
        return response.data;
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );
  export const leadUpdate = createAsyncThunk(
      "leads/lead-update",
      async (values, thunkApi) => {
        try {
          const response = await axiosInstance.put<LeadModel>(
            `${process.env.REACT_APP_BASE_URL}/Ecommerce/Leads/UpdateLeads`,
            {...values}, {
              headers: {
                "Accept":"application/json",
                "Authorization": "Bearer " + readToken()
              }
            });
          return response.data;
        } catch (error) {
          return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
        }
      }
    );
  export const leadDelete = createAsyncThunk(
    "leads/lead-delete",
    async (ids, thunkApi) => {
      try {
        const response = await axiosInstance.delete<LeadModel>(
          `${process.env.REACT_APP_BASE_URL}/Ecommerce/Leads/DeleteLeads`, {
            headers: {
              "Accept":"application/json",
              "Authorization": "Bearer " + readToken()
            },
            params: 
            {
              ids: ids
            }
          });
        return response.data?.data;
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );
  
const leadSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(leadList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(leadList.fulfilled,(state, action: PayloadAction<LeadModel[]>) => {
          state.loading = false;
          state.leads = action.payload;
      });
      builder.addCase(leadList.rejected, (state, action) => {
        state.loading = false;
        state.leads = [];
      });
      // Detail
      builder.addCase(leadDetail.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(leadDetail.fulfilled,(state, action: PayloadAction<LeadModel>) => {
          state.loading = false;
          state.lead = action.payload;
      });
      builder.addCase(leadDetail.rejected, (state, action) => {
        state.loading = false;
        state.lead = null;
      });
      // Save 
      builder.addCase(createLead.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(createLead.fulfilled,(state, action: PayloadAction<AddLead>) => {
          state.loading = false;
          state.lead = action.payload;
      });
      builder.addCase(createLead.rejected, (state, action) => {
        state.loading = false;
        state.leads = [];
      });
      // Update 
      builder.addCase(leadUpdate.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(leadUpdate.fulfilled,(state, action: PayloadAction<LeadModel>) => {
          state.loading = false;
          state.lead = action.payload;
      });
      builder.addCase(leadUpdate.rejected, (state, action) => {
        state.loading = false;
        state.lead = null;
      });
      // Delete
      builder.addCase(leadDelete.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(leadDelete.fulfilled,(state, action: PayloadAction<LeadModel>) => {
          state.loading = false;
          state.lead = action.payload;
      });
      builder.addCase(leadDelete.rejected, (state, action) => {
        state.loading = false;
        state.lead = null;
      });
      // ven Detail
      builder.addCase(leadVendorDetail.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(leadVendorDetail.fulfilled,(state, action: PayloadAction<VendorModel>) => {
          state.loading = false;
          state.leadvendor = action.payload;
      });
      builder.addCase(leadVendorDetail.rejected, (state, action) => {
        state.loading = false;
        state.leadvendor = null;
      });
  }
})

export const thunks = {
  leadList,
  leadDetail,
  createLead,
  leadUpdate,
  leadDelete,
  leadVendorDetail,
};

export default leadSlice.reducer;

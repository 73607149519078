import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import MedicalDashboardPage from '@app/pages/DashboardPages/MedicalDashboardPage';
import FooterLinksListTable from '@app/pages/uiSetupPages/FooterLinksListPage';

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const KanbanPage = React.lazy(() => import('@app/pages/KanbanPage'));
const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const UserListPage = React.lazy(() => import('@app/pages/UserListPage'));
const LeadListPage = React.lazy(() => import('@app/pages/LeadListPage'));
const ProductListPage = React.lazy(() => import('@app/pages/InventoryPages/ProductListPage'));
const ProductInfoPage = React.lazy(() => import('@app/pages/InventoryPages/ProductInfoPage'));
const BrandListPage = React.lazy(() => import('@app/pages/InventoryPages/BrandListPage'));
const ThemeSetupListPage = React.lazy(() => import('@app/pages/InventoryPages/ThemeSetupListPage'));
const TemplateListPage = React.lazy(() => import('@app/pages/InventoryPages/TemplateListPage'));
const TemplatePage = React.lazy(() => import('@app/pages/InventoryPages/TemplatePage'));
const BrandPage = React.lazy(() => import('@app/pages/InventoryPages/BrandPage'));
const ThemeSetupPage = React.lazy(() => import('@app/pages/InventoryPages/ThemeSetupPage'));
const CompanyPage = React.lazy(() => import('@app/pages/InventoryPages/CompanyPage'));
const PaymentProvidersPage = React.lazy(() => import('@app/pages/PaymentProvidersPage'));
const StoreSetupPage = React.lazy(() => import('@app/pages/StoreSetupPage'));
const  AnalyticsPage = React.lazy(() => import('@app/pages/AnalyticsPage'));
const  OrdersPage = React.lazy(() => import('@app/pages/OrderPage'));
const CheckoutSetupPage = React.lazy(() => import('@app/pages/CheckoutSetupPage'));
const LoyaltyListPage = React.lazy(() => import('@app/pages/LoyaltyListPage'));
const CustomersListPage = React.lazy(() => import('@app/pages/CustomersListPage'));
const OffersListPage = React.lazy(() => import('@app/pages/InventoryPages/OffersListPage'));
const ZoneListPage = React.lazy(() => import('@app/pages/InventoryPages/ZoneListPage'));
const OffersPage = React.lazy(() => import('@app/pages/InventoryPages/OffersPage'));
const LoyaltyFormPage = React.lazy(() => import('@app/pages/LoyaltyFormPage'));
const ZonePage = React.lazy(() => import('@app/pages/InventoryPages/ZonePage'));
const RegionPage = React.lazy(() => import('@app/pages/InventoryPages/RegionPage'));
const MenuPage = React.lazy(() => import('@app/pages/InventoryPages/MenuPage'));
const EditMenuPage = React.lazy(() => import('@app/pages/InventoryPages/EditMenuPage'));
const PromotionListPage = React.lazy(() => import('@app/pages/InventoryPages/PromotionListPage'));
const PromotionBannerListPage = React.lazy(() => import('@app/pages/InventoryPages/PromotionBannerListPage'));
const BlogListPage = React.lazy(() => import('@app/pages/InventoryPages/BlogListPage'));
const subcategoryProductListPage = React.lazy(() => import('@app/pages/InventoryPages/SubCategoryProductListPage '));
const CategoryProductListPage = React.lazy(() => import('@app/pages/InventoryPages/CategoryProductListPage '));
const VendorProductListPage = React.lazy(() => import('@app/pages/InventoryPages/SellerProductListPage'));
const ProductOrderListPage = React.lazy(() => import('@app/pages/InventoryPages/ProductOrderListPage'));
const AddPromotionPage = React.lazy(() => import('@app/pages/InventoryPages/AddPromotionPage'));
const AddPromotionBannerPage = React.lazy(() => import('@app/pages/InventoryPages/AddPromotionBannerPage'));
const AddBlogPage = React.lazy(() => import('@app/pages/InventoryPages/AddBlogPage'));
const MessageProvidersListPage = React.lazy(() => import('@app/pages/InventoryPages/MessageProvidersListPage'));
const AddMessageProvidersPage = React.lazy(() => import('@app/pages/InventoryPages/AddMessageProvidersPage'));
const EmailConfigListPage = React.lazy(() => import('@app/pages/InventoryPages/EmailConfigListPage'));
const AddEmailConfigPage = React.lazy(() => import('@app/pages/InventoryPages/AddEmailConfigPage'));
const ShippingProfileListPage = React.lazy(() => import('@app/pages/InventoryPages/ShippingProfileListPage'));
const AddShippingProfilePage = React.lazy(() => import('@app/pages/InventoryPages/AddShippingProfilePage'));
const ParentCategoryListPage = React.lazy(() => import('@app/pages/InventoryPages/ParentCategoryListPage'));
const SubCategoryListPage = React.lazy(() => import('@app/pages/InventoryPages/SubCategoryListPage'));
const FooterSectionListpage = React.lazy(() => import('@app/pages/InventoryPages/FooterSectionListPage'));
const AddParentCategoryPage = React.lazy(() => import('@app/pages/InventoryPages/AddParentCategoryPage'));
const AddSubCategoryPage = React.lazy(() => import('@app/pages/InventoryPages/AddSubCategoryPage'));
const AddFooterSectionPage = React.lazy(() => import('@app/pages/InventoryPages/AddFooterSectionPage'));
const AddConfigurationPage = React.lazy(() => import('@app/pages/InventoryPages/AddConfigurationPage'));
const ApiKeysPage = React.lazy(() => import('@app/pages/InventoryPages/ApiKeysPage'));
const AddTemplateConfigurationPage = React.lazy(() => import('@app/pages/InventoryPages/AddTemplateConfigPage'));
const TemplateConfigurationListPage = React.lazy(() => import('@app/pages/InventoryPages/TemplateConfigListPage'));
const VendorListPage = React.lazy(() => import('@app/pages/VendorListPage'));
// const UserDetailPage = React.lazy(() => import('@app/pages/UserEdit'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const AddVendorFormsPage = React.lazy(() => import('@app/pages/AddVendorFormsPage'));
const FooterLinksFormsPage = React.lazy(() => import('@app/pages/uiSetupPages/FooterLinksFormsPage'));
const FooterLinksListPage = React.lazy(() => import('@app/pages/uiSetupPages/FooterLinksListPage'));
const AddVendorForms = React.lazy(() => import('@app/pages/AddVendorForms'));
const FooterLinksForms = React.lazy(() => import('@app/pages/uiSetupPages/FooterLinksForms'));
const NewPasswordFormsPage = React.lazy(() => import('@app/pages/NewPasswordFormsPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const AddUserFormsPage = React.lazy(() => import('@app/pages/AddUserFormsPage'));
const AddLeadFormsPage = React.lazy(() => import('@app/pages/AddLeadFormsPage'));
const VendorDetailPage = React.lazy(() => import('@app/pages/VendorInfoPage'));
const LeadDetailPage = React.lazy(() => import('@app/pages/LeadInfoPage'));
const ProductPage = React.lazy(() => import('@app/pages/InventoryPages/ProductPage'));
const VendorProductPage = React.lazy(() => import('@app/pages/InventoryPages/VendorProductListPage'));
const OrderListPage = React.lazy(() => import('@app/pages/InventoryPages/OrderListPage'));
const FulfillmentListPage = React.lazy(() => import('@app/pages/InventoryPages/FulfillmentListPage'));
const FulfillmentParentListPage = React.lazy(() => import('@app/pages/InventoryPages/FulfillmentParentListPage'));
const VendorOrderListPage = React.lazy(() => import('@app/pages/InventoryPages/VendorOrderListPage'));
const CustomerOrderDetailPage = React.lazy(() => import('@app/pages/InventoryPages/CustomerOrderListPage'));
const CustomerDetailPage = React.lazy(() => import('@app/pages/InventoryPages/CustomerDetailListPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const MedicalDashboard = withLoading(MedicalDashboardPage);
const NftDashboard = withLoading(NftDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const Kanban = withLoading(KanbanPage);

const UserForm = withLoading(AddUserFormsPage);
const UserList = withLoading(UserListPage);
// const UserDetail = withLoading(UserDetailPage);
const LeadForm = withLoading(AddLeadFormsPage);
const LeadList = withLoading(LeadListPage);
const ProductList = withLoading(ProductListPage);
const ProductInfo = withLoading(ProductInfoPage);
const BrandList = withLoading(BrandListPage);
const ThemeSetupList = withLoading(ThemeSetupListPage);
const TemplateList = withLoading(TemplateListPage);
const TemplateForm = withLoading(TemplatePage);
const BrandForm = withLoading(BrandPage);
const ThemeSetupForm = withLoading(ThemeSetupPage);
const CompanyForm = withLoading(CompanyPage);
const PaymentProvidersForm = withLoading(PaymentProvidersPage);
const StoreSetupFrom = withLoading(StoreSetupPage);
const AnalyticsForm = withLoading(AnalyticsPage);
const OrdersForm = withLoading(OrdersPage);
const CheckoutSetupFrom = withLoading(CheckoutSetupPage);
const LoyaltyList = withLoading(LoyaltyListPage);
const CustomersListTable = withLoading(CustomersListPage);
const OffersList = withLoading(OffersListPage);
const ZoneList = withLoading(ZoneListPage);
const OffersForm = withLoading(OffersPage);
const LoyaltyForm = withLoading(LoyaltyFormPage);
const ZoneForm = withLoading(ZonePage);
const RegionForm = withLoading(RegionPage);
const MenuForm = withLoading(MenuPage);
const EditMenu = withLoading(EditMenuPage);
const PromotionList = withLoading(PromotionListPage);
const PromotionBannerList = withLoading(PromotionBannerListPage);
const BlogList = withLoading(BlogListPage);
const EmailConfigList = withLoading(EmailConfigListPage);
const AddEmailConfig = withLoading(AddEmailConfigPage);
const AddPromotion = withLoading(AddPromotionPage);
const AddPromotionBanner = withLoading(AddPromotionBannerPage);
const AddBlog = withLoading(AddBlogPage);
const ShippingProfileList = withLoading(ShippingProfileListPage);
const AddShippingProfile = withLoading(AddShippingProfilePage);
const AddMessageProviders = withLoading(AddMessageProvidersPage);
const MessageProvidersList = withLoading(MessageProvidersListPage);
const ParentCategoryList = withLoading(ParentCategoryListPage);
const SubCategoryList = withLoading(SubCategoryListPage);
const SubcategoryProductListPage = withLoading(subcategoryProductListPage);
const CategoryProducttListPage = withLoading(CategoryProductListPage);
const VendorProductList = withLoading(VendorProductListPage);
const ProductOrderList = withLoading(ProductOrderListPage);
const FooterSectionList = withLoading(FooterSectionListpage);
const LeadDetail = withLoading(LeadDetailPage);
const VendorForm = withLoading(AddVendorFormsPage);
const FooterLinksForm = withLoading(FooterLinksFormsPage);
const FooterLinksTable = withLoading(FooterLinksListPage);
const AddVendor = withLoading(AddVendorForms);
const VendorList = withLoading(VendorListPage);
const FooterLinks = withLoading(FooterLinksForms);
const AddPatentCategoty = withLoading(AddParentCategoryPage);
const AddSubCategoty = withLoading(AddSubCategoryPage);
const AddFooterSection = withLoading(AddFooterSectionPage);
const AddConfiguration = withLoading(AddConfigurationPage);
const ApiKeys = withLoading(ApiKeysPage);
const AddTemplateConfiguration = withLoading(AddTemplateConfigurationPage);
const TemplateConfigurationList = withLoading(TemplateConfigurationListPage);
const VendorDetail = withLoading(VendorDetailPage);
const NewPassword = withLoading(NewPasswordFormsPage);
const AdvancedForm = withLoading(AdvancedFormsPage);
const ProductForm = withLoading(ProductPage);
const VendorProductForm = withLoading(VendorProductPage);
const OrderListForm = withLoading(OrderListPage);
const FulfillmentListForm = withLoading(FulfillmentListPage);
const FulfillmentParentListForm = withLoading(FulfillmentParentListPage);
const VendorOrderListForm = withLoading(VendorOrderListPage);
const CustomerOrderDetail = withLoading(CustomerOrderDetailPage);
const CustomerDetail = withLoading(CustomerDetailPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<NftDashboard />} />
          <Route path={MEDICAL_DASHBOARD_PATH} element={<MedicalDashboard />} />
          <Route path="payment-providers" element={<PaymentProvidersForm />} />
          <Route path="customers-List" element={<CustomersListTable />} />
          <Route path="customer-info/:rowId" element={<CustomerDetail />} />
          <Route path="apps">
            <Route path="feed" element={<NewsFeed />} />
            <Route path="kanban" element={<Kanban />} />
          </Route>
          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
          </Route>
          <Route path="user">
            <Route path="add-user" element={<UserForm />} />
            <Route path="user-list" element={<UserList />} />
            {/* <Route path="user-detail" element={<UserDetail />} /> */}
          </Route>
          <Route path="leads">
            <Route path="lead-request-form" element={<LeadForm />} />
            <Route path="all-lead" element={<LeadList />} />
            <Route path="lead-detail" element={<LeadDetail />} />
          </Route>
          <Route path="vendors">
            <Route path="add-vendor" element={<AddVendor />} />
            <Route path="onboard-vendor" element={<VendorForm />} />
            <Route path="all-vendor" element={<VendorList />} />
            <Route path="vendor-detail" element={<VendorDetail />} />
            <Route path="vendor-detail/:rowId" element={<VendorDetail />} />
          </Route>
          <Route path="uiSetup">
            <Route path="all-footers" element={<FooterLinksTable />} />
            <Route path="add-footers" element={<FooterLinksForm />} />
            <Route path="api-config" element={<ApiKeys />} />
          </Route>
          <Route path="product">
            <Route path="add-product" element={<ProductForm />} />
            <Route path="all-product" element={<ProductList />} />
            <Route path="product-detail" element={<ProductInfo />} />
            <Route path="all-vendor-product" element={<VendorProductForm />} />
          </Route>
          <Route path="category">
            <Route path="parentcategory-list" element={<ParentCategoryList />} />
            <Route path="subcategory-list" element={<SubCategoryList />} />
            <Route path="add-parent-category" element={<AddPatentCategoty />} />
            <Route path="add-sub-category" element={<AddSubCategoty />} />
            <Route path="add-footer-section" element={<AddFooterSection />} />
            <Route path="footersection-list" element={<FooterSectionList />} />
          </Route>
          <Route path="configuration">
            <Route path="templateConfiguration-list" element={<TemplateConfigurationList />} />
            <Route path="add-templateConfiguration" element={<AddTemplateConfiguration />} />
          </Route>
          <Route path="theme">
            <Route path="setTheme" element={<AddConfiguration />} />
          </Route>
          <Route path="brand">
            <Route path="brand-list" element={<BrandList />} />
            <Route path="add-brand" element={<BrandForm />} />
          </Route>
          <Route path="theme">
            <Route path="setup-list" element={<ThemeSetupList />} />
            <Route path="setup-add" element={<ThemeSetupForm />} />
          </Route>
          <Route path="template">
            <Route path="template-list" element={<TemplateList />} />
            <Route path="add-template" element={<TemplateForm />} />
            <Route path="add-template/:rowId" element={<TemplateForm />} />
          </Route>
          <Route path="promotion">
            <Route path="promotion-list" element={<PromotionList />} />
            <Route path="add-promotion" element={<AddPromotion />} />
          </Route>
          <Route path="promotionBanner">
            <Route path="promotionBanner-list" element={<PromotionBannerList />} />
            <Route path="add-promotionBanner" element={<AddPromotionBanner />} />
          </Route>
          <Route path="blog">
            <Route path="blog-list" element={<BlogList />} />
            <Route path="add-blog" element={<AddBlog />} />
          </Route>
          <Route path="analytics">
            <Route path="analytics-list" element={<AnalyticsForm />} />
            <Route path="Order-list" element={<OrdersForm />} />
          </Route>
         <Route path="company">
            <Route path="add-company" element={<CompanyForm />} />
          </Route>
          <Route path="emailConfig">
            <Route path="emailConfig-list" element={<EmailConfigList />} />
            <Route path="add-emailConfig" element={<AddEmailConfig />} />
            <Route path="add-emailConfig/:rowId" element={<AddEmailConfig />} />
          </Route>
          <Route path="messageProviders">
            <Route path="messageProviders-list" element={<MessageProvidersList />} />
            <Route path="add-messageProviders" element={<AddMessageProviders />} />
            <Route path="add-messageProviders/:rowId" element={<AddMessageProviders />} />
          </Route>
          <Route path="shippingProfile">
            <Route path="shippingProfile-list" element={<ShippingProfileList />} />
            <Route path="add-shippingProfile" element={<AddShippingProfile />} />
            <Route path="add-shippingProfile/:rowId" element={<AddShippingProfile />} />
          </Route>
          <Route path="order">
            <Route path="order-list" element={<OrderListForm />} />
            <Route path="fulfillment-list" element={<FulfillmentListForm />} />
            <Route path="fulfillment-parentlist" element={<FulfillmentParentListForm />} />
            <Route path="all-seller-order" element={<VendorOrderListForm />} />
            <Route path="customer-detail" element={<CustomerOrderDetail />} />
            <Route path="customer-detail/:rowId" element={<CustomerOrderDetail />} />
          </Route>
          <Route path="offers">
            <Route path="add-loyalty" element={<LoyaltyForm />} />
            <Route path="info-loyalty/:id" element={<LoyaltyForm />} />
            <Route path="loyalty" element={<LoyaltyList />} />
            <Route path="offers-list" element={<OffersList />} />
            <Route path="add-offers" element={<OffersForm />} />
          </Route>
          <Route path="zone">
            <Route path="zone-list" element={<ZoneList />} />
            <Route path="add-zone" element={<ZoneForm />} />
          </Route>
          <Route path="region">
            {/* <Route path="region-list" element={<RegionList />} /> */}
            <Route path="add-region" element={<RegionForm />} />
          </Route>
          <Route path="Menu">
            <Route path="add-Menu" element={<MenuForm />} />
            <Route path="editMenu-detail" element={<EditMenu />} />
          </Route>
          <Route path="setup">
            <Route path="store-setup" element={<StoreSetupFrom />} />
            <Route path="checkout" element={<CheckoutSetupFrom />} />
          </Route>
          <Route path="data-tables" element={<DataTables />} />
          <Route path="charts" element={<Charts />} />
          <Route path="maps">
            <Route path="google-maps" element={<Google />} />
            <Route path="leaflet-maps" element={<Leaflet />} />
            <Route path="react-simple-maps" element={<ReactSimple />} />
            <Route path="pigeon-maps" element={<Pigeons />} />
          </Route>
          <Route path="/auth/new-password" element={<NewPassword />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<Payments />} />
          </Route>
          <Route path="ui-components">
            <Route path="button" element={<Buttons />} />
            <Route path="spinner" element={<Spinners />} />
            <Route path="input" element={<Inputs />} />
            <Route path="checkbox" element={<Checkboxes />} />
            <Route path="radio" element={<Radios />} />
            <Route path="select" element={<Selects />} />
            <Route path="switch" element={<Switches />} />
            <Route path="upload" element={<Uploads />} />
            <Route path="rate" element={<Rates />} />
            <Route path="auto-complete" element={<AutoCompletes />} />
            <Route path="steps" element={<Steps />} />
            <Route path="date-time-picker" element={<DateTimePickers />} />
            <Route path="dropdown" element={<Dropdowns />} />
            <Route path="breadcrumbs" element={<Breadcrumbs />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="avatar" element={<Avatars />} />
            <Route path="badge" element={<Badges />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="modal" element={<Modals />} />
            <Route path="popover" element={<Popovers />} />
            <Route path="popconfirm" element={<Popconfirms />} />
            <Route path="progress" element={<Progress />} />
            <Route path="result" element={<Results />} />
            <Route path="alert" element={<Alerts />} />
            <Route path="notification" element={<NotificationsUI />} />
            <Route path="skeleton" element={<Skeletons />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          {/* <Route path="sign-up" element={<SignUpPage />} /> */}
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          {/* <Route path="new-password" element={<NewPasswordPage />} /> */}
        </Route>
          <Route path="/auth/sign-up" element={<SignUpPage />}/>
        <Route path="/auth/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};

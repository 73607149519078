import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { jwtDecode } from "jwt-decode";
import dayjs from 'dayjs';

interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

// Define a default bypass token check flag
axiosInstance.defaults.headers.common['X-Bypass-Token-Check'] = false;

// Add a request interceptor
axiosInstance.interceptors.request.use(async (req: AxiosRequestConfig) => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    req.headers = req.headers || {};
    req.headers.Authorization = `Bearer ${accessToken}`;
  }

  // Decode the JWT to get expiration time
  let tokenData: DecodedToken | null = null;

  try {
    tokenData = jwtDecode<DecodedToken>(accessToken || '');

  } catch (error) {
    console.error('Failed to decode token', error);
  }

  const isExpired = dayjs.unix(tokenData.exp).diff(dayjs()) < 1;

  if (!isExpired) return req;

  if (isExpired) {
    window.location.href = "/auth/login";
    localStorage.removeItem('accessToken');
  }
  const refreshToken = localStorage.getItem('refreshToken');

  if (refreshToken) {
    try {
      const response: AxiosResponse<RefreshTokenResponse> = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/Auth/refresh`,
        {
          accessToken,
          refreshToken,
          companyId: localStorage.getItem('companyId'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      req.headers.Authorization = `Bearer ${response.data.accessToken}`;
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
    } catch (error) {
      console.error('Failed to refresh token', error);
    }
  }

  return req;
});

export default axiosInstance;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { OffersModel } from '@app/domain/OffersModel';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';

export interface OffersState {
  loading: boolean;
  offers: OffersModel[] | [];
  offer: OffersModel | null;
}

const initialState: OffersState = {
  loading: false,
  offers: [],
  offer: null,
};

export const offersList = createAsyncThunk('/offers/offers-list', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<OffersModel[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Offers/GetAllOffersList`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});


export const addOffers = createAsyncThunk('offers/add-offers', async (values, thunkApi) => {
  try {
    const response = await axiosInstance.post<OffersModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Offers/SaveOffers`,
      {...values},
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const updateOffers = createAsyncThunk(
  "offers/Update-offers",
  async (values, thunkApi) => {
    try {
      const response = await axiosInstance.put<OffersModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Offers/UpdateOffers`,
         {...values, },
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const deleteOffers = createAsyncThunk('offers/delete-offers', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.delete<OffersModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Offers/DeleteOffers`,
      {
        params: {
          Id: id
        },
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const offersDetails = createAsyncThunk(
  "offers/view-offers",
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get<OffersModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Offers/GetOffersDetailsById`,{
          params: 
          {
            Id: id
          },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(offersList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(offersList.fulfilled, (state, action: PayloadAction<OffersModel[]>) => {
      state.loading = false;
      state.offers = action.payload;
    });
    builder.addCase(offersList.rejected, (state, action) => {
      state.loading = false;
      state.offers = [];
    });
   // details
    builder.addCase(offersDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(offersDetails.fulfilled, (state, action: PayloadAction<OffersModel>) => {
      state.loading = false;
      state.offer = action.payload;
    });
    builder.addCase(offersDetails.rejected, (state, action) => {
      state.loading = false;
      state.offer =null;
    });
  },
});

export const thunks = {
  offersList,
  offersDetails,
};

export default offersSlice.reducer;

import { createAction, createAsyncThunk, createSlice, PayloadAction, PrepareAction } from '@reduxjs/toolkit';
import { UserModels } from '@app/domain/UserModels';
import { CustomersModels } from '@app/domain/CustomersModel';
import { getLoggedInCompanyId, persistUser, readUser } from '@app/services/localStorage.service';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { readToken } from '@app/services/localStorage.service';

export interface UserState {
  loading: boolean;
  users: UserModels[] | null;
  user: UserModels | null;
  customers: CustomersModels[] | null;
  customer: CustomersModels | null;
  CustomersDetails: CustomersModels[] | null;
  CustomersExcel: CustomersModels[] | null;
}

const initialState: UserState = {
  // user: readUser(),
  users: [],
  user: null,
  customers: [],
  customer: null,
  loading: false,
  CustomersDetails: [],
  CustomersExcel : [],
};

export const setUser = createAction<PrepareAction<UserModels>>('user/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});

export const usersList = createAsyncThunk('/users/user-list', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<UserModels[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Users/GetAdminUsersList`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const customersList = createAsyncThunk('/customers-list', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<CustomersModels[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Users/GetAllUserList`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const exportExcelcustomersList = createAsyncThunk('/customers-list-excel', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get<CustomersModels[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Users/GetExportExcelUsers`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const CustomersDetailsById = createAsyncThunk('/customer', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.get<CustomersModels[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Users/GetCustomerDetailsbyId/${id}`,
      // Ecommerce/Users/GetCustomerDetailsbyId
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

console.log("Customersdetail",CustomersDetailsById);


export const addUser = createAsyncThunk('user/add-user', async (values, thunkApi) => {
  try {
    const response = await axiosInstance.post<UserModels>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Users/SaveUsers`,
      {...values },
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const userDetails = createAsyncThunk('user/user-details', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.get<UserModels>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Users/GetUsersDetailsById/${id}`,
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const UpdateUser = createAsyncThunk('user/update-user', async (values, thunkApi) => {
  try {
    const response = await axiosInstance.put<UserModels>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Users/UpdateUsers`,
      {...values},
      {
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const DeleteAdminUser = createAsyncThunk('AdminUser/delete-AdminUser', async (id, thunkApi) => {
  try {
    const response = await axiosInstance.delete<UserModels>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Users/DeleteAdminUser`,
      {
        params: {
          Id: id
        },
       headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(usersList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(usersList.fulfilled, (state, action: PayloadAction<UserModels[]>) => {
      state.loading = false;
      state.users = action.payload;
    });
    builder.addCase(usersList.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
    });
    builder.addCase(customersList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(customersList.fulfilled, (state, action: PayloadAction<CustomersModels[]>) => {
      state.loading = false;
      state.customers = action.payload;
    });
    builder.addCase(customersList.rejected, (state, action) => {
      state.loading = false;
      state.customers = [];
    });
    builder.addCase(exportExcelcustomersList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(exportExcelcustomersList.fulfilled, (state, action: PayloadAction<CustomersModels[]>) => {
      state.loading = false;
      state.CustomersExcel = action.payload;
    });
    builder.addCase(exportExcelcustomersList.rejected, (state, action) => {
      state.loading = false;
      state.CustomersExcel = [];
    });
    builder.addCase(userDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userDetails.fulfilled, (state, action: PayloadAction<UserModels>) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(userDetails.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
    });
    builder.addCase(CustomersDetailsById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(CustomersDetailsById.fulfilled, (state, action: PayloadAction<UserModels[]>) => {
      state.loading = false;
      state.CustomersDetails = action.payload;
    });
    builder.addCase(CustomersDetailsById.rejected, (state, action) => {
      state.loading = false;
      state.CustomersDetails = [];
    });
  },
});

export const thunks = {
  usersList,
  customersList,
  addUser,
  CustomersDetailsById,
  exportExcelcustomersList
  // UserModels,
  // updateProduct,
  // productStatusUpdate,
};

export default userSlice.reducer;

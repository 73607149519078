import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductModel,orderTotal } from '@app/domain/ProductModel';
import { ProductOrderModel} from '@app/domain/ProductOrderModel';
import axiosInstance from './../../store/AxiosInstance';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';

export interface ProductState {
  loading: boolean;
  productorders: ProductModel[] | [];
  totalOrders: orderTotal[] | [];
  invoiceProduct: ProductOrderModel | null;
}

const initialState: ProductState = {
  loading: false,
  productorders: [],
  totalOrders:[],
  invoiceProduct:null
};

export const productOrderList = createAsyncThunk("/products/productOrder-list",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get<ProductModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ProductOrder/GetAllProductOrderTotalList`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const invoiceProductDetails = createAsyncThunk('products/view-productOrder',
  async (OrderId, thunkApi) => {
  try {   
    const response = await axiosInstance.get<ProductOrderModel[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/ProductOrder/GetInvoiceProductDetailsById`,
      {
        params: {
          OrderId: OrderId
        },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const totalOrderList = createAsyncThunk("/products/totalOrder-list",async (Year, thunkApi) => {
    try {
      const response = await axiosInstance.get<orderTotal[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ProductOrder/GetTotalOrderDetailsByMonth`,
        {
          params: {
            Year: Year
          },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const productorderSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(productOrderList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(productOrderList.fulfilled, (state, action: PayloadAction<ProductModel[]>) => {
      state.loading = false;
      state.productorders = action.payload;
    });
    builder.addCase(productOrderList.rejected, (state, action) => {
      state.loading = false;
      state.productorders = [];
    });
    builder.addCase(invoiceProductDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(invoiceProductDetails.fulfilled, (state, action: PayloadAction<ProductOrderModel[]>) => {
      state.loading = false;
      state.invoiceProduct = action.payload;
    });
    builder.addCase(invoiceProductDetails.rejected, (state, action) => {
      state.loading = false;
      state.invoiceProduct = [];
    });
    // Total Order List
    builder.addCase(totalOrderList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(totalOrderList.fulfilled, (state, action: PayloadAction<orderTotal[]>) => {
      state.loading = false;
      state.totalOrders = action.payload;
    });
    builder.addCase(totalOrderList.rejected, (state, action) => {
      state.loading = false;
      state.totalOrders = [];
    });
   },
});

export const thunks = {
  productOrderList,
  invoiceProductDetails
};

export default productorderSlice.reducer;
